import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "components/api/httpService";

const ResetPassword = () => {
    const [isTokenValid, setIsTokenValid] = useState(null);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    // Lấy token từ URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await axios.post(`${baseUrl}/api/auth/manage-password/confirm-token-admin`, { token });
                if (response.status === 200) {
                    setIsTokenValid(true);
                } else {
                    setIsTokenValid(false);
                }
            } catch (error) {
                setIsTokenValid(false);
            }
        };

        verifyToken();
    }, [token]);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${baseUrl}/api/auth/manage-password/change-pass-fogot-admin`, {
                token,
                newPassword,
                confirmPassword
            });

            if (response.status === 200) {
                setSuccessMessage("Password has been reset successfully.");
                setTimeout(() => {
                    window.location.href = "/";
                }, 2000);
            } else {
                setErrorMessage("Failed to reset password.");
            }
        } catch (error) {
            setErrorMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div className="max-w-md mx-auto p-5 border border-gray-300 rounded-lg shadow-lg">
            {isTokenValid === null ? (
                <p>Loading...</p>
            ) : isTokenValid ? (
                <form onSubmit={handlePasswordReset}>
                    <h2 className="text-center text-2xl mb-5">Reset Password</h2>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">New Password:</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}
                    <button 
                        type="submit" 
                        className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Submit
                    </button>
                </form>
            ) : (
                <p className="text-red-500 text-center">The token has expired. Please request a new one.</p>
            )}
            {successMessage && <p className="text-green-500 text-center mt-2">{successMessage}</p>}
        </div>
    );
};

export default ResetPassword;
