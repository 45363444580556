function Attendance() {
    document.title = "Attendance";

    return (
        <>
            <h1>This is Attendance</h1>
        </>
    );
}
 
export default Attendance;
