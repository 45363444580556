import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "components/api/httpService";

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!currentPassword || !newPassword || !confirmPassword) {
            setError("All fields are required.");
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("New password and confirm password do not match.");
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/auth/password/change-pass`, {
                currentPassword,
                newPassword,
                confirmPassword
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
        );

            if (response.status === 200) {
                setMessage("Password has been changed successfully.");
                setError("");
            } else {
                setError("Failed to change password. Please try again.");
                setMessage("");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            setMessage("");
        }
        setIsLoading(false);
    };

    return (
        <div className="max-w-md mx-auto mt-5 p-5 border border-gray-300 rounded-lg shadow-lg mt-4">
            <h2 className="text-center text-2xl mb-5">Change Password</h2>
            <form onSubmit={handleChangePassword}>
                <div className="mb-4">
                    <label className="block mb-1 font-semibold">Current Password:</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-semibold">New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-semibold">Confirm New Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <button 
                    type="submit" 
                    disabled={isLoading}
                    className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {isLoading ? "Changing..." : "Change Password"}
                </button>
            </form>
            {message && <p className="text-green-500 text-center mt-2">{message}</p>}
            {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        </div>
    );
};

export default ChangePassword;
