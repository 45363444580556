import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "components/api/httpService";

const ListRecipeService = () => {
    const [recipes, setRecipes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const limit = 10;

    // Hàm gọi API để lấy danh sách RecipeLito
    const fetchRecipes = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/crm/RecipeService/getAll?page=${page}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            setRecipes(response.data.recipes);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching RecipeService:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRecipes(currentPage);
    }, [currentPage]);

    const handleUseRecipe = async (id) => {
        try {
            await axios.post(`${baseUrl}/api/crm/RecipeService/used`, { id }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            fetchRecipes(currentPage);
        } catch (error) {
            alert("Error using RecipeService:", error);
        }
    };

    const handleDeleteRecipe = async (id) => {
        const confirmDelete = window.confirm("Sind Sie sicher, dass Sie dieses Rezept löschen möchten? Wenn das Rezept verwendet wird, wird die Berechnung auf den Standardwert zurückgesetzt.");
        if (confirmDelete) {
            try {
                await axios.delete(`${baseUrl}/api/crm/RecipeService/delete/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                fetchRecipes(currentPage);
            } catch (error) {
                alert("Es gab einen Fehler beim Löschen des Rezepts.");
            }
        }
    };

    return (
        <div className="ml-[260px] flex flex-col font-Changa text-textColor">
            <div className="p-5 flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">List Recipe Service</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl hover:text-black">/ list recipe service</span>
                    </div>
                </div>
                <div className="flex flex-row gap-3">
                    <Link to="/add-recipe-service" className="bg-buttonColor1 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md hover:bg-cyan-800">
                        Add
                    </Link>
                </div>
            </div>

            <div className="container mx-auto p-4">
                {loading ? (
                    <div className="text-center py-4">
                        <span>Loading...</span>
                    </div>
                ) : (
                    <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-md">
                        <thead>
                            <tr className="border-b border-gray-300">
                                <th className="py-2 px-4 text-left text-gray-600">#</th>
                                <th className="py-2 px-4 text-left text-gray-600" colSpan={2}>Name</th>
                                <th className="py-2 px-4 text-left text-gray-600">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recipes.map((recipe, index) => (
                                <tr className="border-b border-gray-200" key={recipe._id}>
                                    <td className="py-2 px-4 text-gray-700">{(currentPage - 1) * limit + index + 1}</td>
                                    <td className="py-2 px-4 text-gray-700" colSpan={2}>{recipe.name}</td>
                                    <td className="py-2 px-4">
                                        <div className="flex items-center">
                                            {recipe.used ? (
                                                <></>
                                            ) : (
                                                <button
                                                    className="bg-green-500 text-white font-semibold py-2 px-4 rounded shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 text-base"
                                                    onClick={() => handleUseRecipe(recipe._id)}
                                                >
                                                    Use
                                                </button>
                                            )}
                                            <Link
                                                to={`/edit-recipe-service/${recipe._id}`}
                                                className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2 text-base"
                                            >
                                                Edit
                                            </Link>
                                            <button
                                                onClick={() => handleDeleteRecipe(recipe._id)}
                                                className="bg-red-500 text-white font-semibold py-2 px-4 rounded shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 ml-2 text-base"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {/* Phân trang */}
            <div className="flex flex-wrap gap-2 justify-center items-center mt-4 mb-4">
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`px-4 py-2 mx-1 border border-gray-300 rounded-md 
                            ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-100'}`}>
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ListRecipeService;
