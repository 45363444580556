import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from "components/api/httpService";

const EmployeeRequestDetail = () => {
    const { id } = useParams();
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        fetchEmployeeDetails();
    }, []);

    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/crm/get-employee-request-detail/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setEmployee(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching employee details:", error);
            setLoading(false);
        }
    };

    if (loading) {
        return <div className="flex items-center justify-center h-screen">Loading...</div>;
    }

    return (
        <div className="relative ml-[260px] h-auto p-5 flex flex-col font-Changa text-textColor gap-5 justify-center">
            <div className="flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">Mitarbeiter Anfrage</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/dashboard">Dashboard</Link>
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/employeeRequest">/ Mitarbeiter Anfragen</Link>
                        <span className="text-[#6c757d] font-xl">/ Mitarbeiter Anfrage Detail</span>
                    </div>
                </div>
            </div>

            {/* Profilbild */}
            {employee.image && (
                <div className="mb-6">
                    <img src={employee.image} alt="Profil" onClick={openModal} className="w-48 h-48 rounded-full object-cover mx-auto shadow-lg" />
                </div>
            )}

            {/* Persönliche Details */}
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h3 className="font-semibold">Nachname</h3>
                    <p>{employee.surname}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Vorname</h3>
                    <p>{employee.name}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Geburtsdatum</h3>
                    <p>{employee.dob}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Geburtsort</h3>
                    <p>{employee.placeOfBirth}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Nationalität</h3>
                    <p>{employee.national}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Geschlecht</h3>
                    <p>{employee.gender}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Familienstand</h3>
                    <p>{employee.maritalStatus}</p>
                </div>
            </div>

            {/* Adressdetails */}
            <h3 className="font-semibold mt-6">Adresse</h3>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h4 className="font-semibold text-lg">Straße</h4>
                    <p className="text-base">{employee.street}</p>
                </div>
                <div>
                    <h4 className="font-semibold text-lg">Hausnummer</h4>
                    <p className="text-base">{employee.hourseNumber}</p>
                </div>
                <div>
                    <h4 className="font-semibold text-lg">Stadt</h4>
                    <p className="text-base">{employee.city}</p>
                </div>
                <div>
                    <h4 className="font-semibold text-lg">Postleitzahl</h4>
                    <p className="text-base">{employee.zipCode}</p>
                </div>
            </div>


            {/* Kinder Details */}
            {employee.children.length > 0 && (
                <div className="mt-6">
                    <h3 className="font-semibold">Kinder</h3>
                    <table className="min-w-full mt-2 bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border px-4 py-2 text-left">Name</th>
                                <th className="border px-4 py-2 text-left">Geburtsdatum</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employee.children.map((child, index) => (
                                <tr key={index}>
                                    <td className="border px-4 py-2">{child.name}</td>
                                    <td className="border px-4 py-2">{child.dob}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Andere Arbeitsdetails */}
            {employee.anotherWork && employee.anotherWorkDetails.length > 0 && (
                <div className="mt-6">
                    <h3 className="font-semibold">Andere Arbeitsdetails</h3>
                    <table className="min-w-full mt-2 bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border px-4 py-2 text-left">Firmenname</th>
                                <th className="border px-4 py-2 text-left">Gehalt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employee.anotherWorkDetails.map((work, index) => (
                                <tr key={index}>
                                    <td className="border px-4 py-2">{work.name}</td>
                                    <td className="border px-4 py-2">{work.salaryIs}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Bank und Versicherungsdetails */}
            <h3 className="font-semibold mt-6">Bank & Versicherung</h3>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h3 className="font-semibold">Bankkonto</h3>
                    <p>{employee.bankAccountDetail}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Rentenversicherung</h3>
                    <p>{employee.pensionInsurance}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Krankenversicherung</h3>
                    <p>{employee.healthInsurance}</p>
                </div>
                <div>
                    <h3 className="font-semibold">Steuernummer</h3>
                    <p>{employee.taxNumber}</p>
                </div>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-xl w-full">
                        <button
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                            onClick={closeModal}
                        >
                            ✕
                        </button>
                        <img
                            src={employee.image}
                            alt="Profil"
                            className="w-full h-auto object-cover rounded-lg"
                        />
                    </div>
                </div>
            )}
            <div className="flex justify-center mt-4">
                <Link
                    to="/employeeRequest"
                    className="bg-gray-500 text-white font-semibold py-2 px-4 rounded hover:bg-gray-600 text-center"
                >
                    Back to list employee request
                </Link>
            </div>


        </div>
    );
};

export default EmployeeRequestDetail;
