import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "components/api/httpService";

const EditRecipeCalculatorSalary = () => {
    const { id } = useParams(); // Lấy id từ URL
    const [name, setName] = useState("");
    const [th1, setTh1] = useState("");
    const [th2, setTh2] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    // Hàm lấy thông tin công thức khi trang được tải
    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/crm/recipeSalary/getById/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                const { name, th1, th2 } = response.data;
                setName(name);
                setTh1(th1);
                setTh2(th2);
            } catch (err) {
                setError("Ein Fehler ist beim Laden des Rezepts aufgetreten. Bitte versuchen Sie es erneut.");
            }
        };
        fetchRecipe();
    }, [id]);

    const handleSubmit = async () => {
        setError(""); // Reset error trước khi submit
        try {
            await axios.put(`${baseUrl}/api/crm/recipeSalary/update/${id}`, {
                name,
                th1,
                th2,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            navigate("/list-recipe-salary"); // Quay về trang danh sách nếu thành công
        } catch (err) {
            const errorMessage = err.response?.data?.message || "Có lỗi xảy ra. Vui lòng kiểm tra lại.";
            setError(errorMessage); // Thông báo lỗi từ server
        }
    };

    return (
        <div className="ml-[260px] flex flex-col font-Changa text-textColor">
            <div className="p-5 flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">Edit Recipe Salary</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl hover:text-black">/ edit recipe salary</span>
                    </div>
                </div>
            </div>

            {/* Thêm thông báo lưu ý */}
            <p className="text-red-500 text-center italic mb-4">
                Hinweis: Nur Variablen 'total_times','total_time_per_month','salary_day_off','house_rent_money','total_km_private','a','b','c','d','g','h','k','f', sowie Klammern und mathematische Operatoren sind erlaubt.
                Bitte geben Sie die mathematische Formel korrekt ein.
                <br />
                Beispiel: (a / total_time_per_month) * total_time_per_month + (total_times.toFixed(2) - total_time_per_month) * f -b - c + g + h + k +salary_day_off - house_rent_money + total_km_private * d 
            </p>

            <div className="container mx-auto p-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Name
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />

                <label className="block text-gray-700 text-sm font-bold mb-2">
                    TH1: Tatsächliche Zeit &gt; Vorgesehene Zeit (Monate)
                </label>
                <textarea
                    value={th1}
                    onChange={(e) => setTh1(e.target.value)}
                    className="form-textarea mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mt-4 mb-2">
                    TH2: Tatsächliche Zeit (realistisch) &lt;= Vorgesehene Zeit (Monate) - Standard
                </label>
                <textarea
                    value={th2}
                    onChange={(e) => setTh2(e.target.value)}
                    className="form-textarea mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <button
                    onClick={handleSubmit}
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
                >
                    Update
                </button>

                {error && <p className="text-red-500 mt-2">{error}</p>} {/* Hiển thị thông báo lỗi */}
            </div>
        </div>
    );
}

export default EditRecipeCalculatorSalary;
