import { useEffect, useState } from "react";
import { format } from "date-fns-tz";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { baseUrl } from "components/api/httpService";
import { positionList } from "assets/data/data"
import { Employee } from 'views/Employee/Employee';
import Select from 'react-select';


const SetMultiShift = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [FormState, setFormState] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [dateFormDb, setDateFormDb] = useState("")
    const [selectedShift, setSelectedShift] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [selectedShiftAddShiftForm, setSelectedShiftAddShiftForm] = useState("")
    const [shiftList, setShiftList] = useState()
    const [checkAdmin, setCheckAdmin] = useState(false)
    const [departList, setDepartList] = useState()
    const [selectedDepartmentEmployee, setSelectedDepartmentEmployee] = useState('');
    const [selectedPositionEmployee, setSelectedPositionEmployee] = useState('');
    const [datePicker, setDatePicker] = useState("Select Date")
    const [checkInhaber, setCheckInhaber] = useState(false)
    const [checkManager, setCheckManager] = useState(false)
    const [userObject, setUserObject] = useState()
    const [departmentInhaberOrManager, setDepartmentInhaberOrManager] = useState("")
    const [listEmployee, setListEmployee] = useState([])
    const [selectEmployee, setSelectEmployee] = useState([])

    useEffect(() => {
        const userString = localStorage.getItem('user');
        const userObject = userString ? JSON.parse(userString) : null;
        setUserObject(userObject)
    }, [])



    useEffect(() => {
        if (userObject?.role === 'Admin') {
            setCheckAdmin(true)
            setCheckInhaber(false)
            setCheckManager(false)
        }

        if (userObject?.role === 'Inhaber') {
            setCheckAdmin(false)
            // setSelectedDepartmentEmployee(userObject?.department_name)
            setCheckInhaber(true)
            setCheckManager(false)
        }

        if (userObject?.role === 'Manager') {
            setCheckAdmin(false)
            // setSelectedDepartmentEmployee(userObject?.department_name)
            setCheckInhaber(false)
            setCheckManager(true)
        }
        if (userObject?.role == "Inhaber" || userObject?.role == "Manager") {
            const arrayFilter = userObject?.department?.map((item) => {
                return {
                    name: item.name
                }
            })
            console.log(arrayFilter)
            setDepartmentInhaberOrManager(arrayFilter)
        }

    }, [userObject?.role, userObject?.department_name]);


    useEffect(() => {
        const getAllShifts = async () => {
            if (userObject?.role === "Admin") {
                try {
                    const response = await axios.get(`${baseUrl}/api/admin/manage-shift/get-all`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    // console.log(response.data.message);
                    setShiftList(response.data.message);
                } catch (err) {
                    alert(err.response?.data?.message)
                }
            }

            if (userObject?.role === "Inhaber") {
                try {
                    const response = await axios.get(`${baseUrl}/api/inhaber/manage-shift/get-all`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    // console.log(response.data.message);
                    setShiftList(response.data.message);
                } catch (err) {
                    alert(err.response?.data?.message)
                }
            }
            if (userObject?.role === "Manager") {
                try {
                    const response = await axios.get(`${baseUrl}/api/manager/manage-shift/get-all`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    // console.log(response.data.message);
                    setShiftList(response.data.message);
                } catch (err) {
                    alert(err.response?.data?.message)
                }
            }
        };
        getAllShifts()

        const getDepartment = async () => {
            if (checkAdmin) {
                try {
                    const response = await axios.get(`${baseUrl}/api/admin/manage-department/get-all`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    });
                    setDepartList(response.data);
                } catch (err) {
                    alert(err.response?.data?.message)
                }
            }
        }
        getDepartment()
    }, [userObject, checkAdmin]
    )

    useEffect(() => {
        setSelectEmployee([])

        const getEmployee = async () => {

            try {
                const response = await axios.get(`${baseUrl}/api/crm/get-employee?department=${selectedDepartmentEmployee}&position=${selectedPositionEmployee}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                let listTmp = response.data?.message.map((e) => {
                    return {
                        value: e._id,
                        label: e.name
                    }
                })
                setListEmployee(listTmp);
            } catch (err) {
                alert(err.response?.data?.message)
            }
        }
        getEmployee()


    }, [selectedShiftAddShiftForm, selectedDepartmentEmployee, selectedPositionEmployee])

    const handleMonthChange = (date) => {
        setSelectedMonth(date);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString("en-US");
    };

    const handleSubmit = async () => {
        let eIds = selectEmployee.map((value) => {
            return value.value
        })
        const formattedValues = datePicker.map(formatDate);
        try {
            const response = await axios.post(`${baseUrl}/api/crm/createMultiSiftForMultiEmployee`,
                {
                    shift_code: selectedShiftAddShiftForm,
                    "employees": eIds,
                    "dates": formattedValues,
                    "department_name": selectedDepartmentEmployee,
                    "position": selectedPositionEmployee
                }
                , {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    },

                });

        } catch (err) {
            alert(err.response?.data?.message)
        }
        
        setFormState(false)
    }


    const handleClickDay = (value, event) => {

        setFormState(true);

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localDate = format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", { timeZone });
        const inputDate = new Date(localDate);
        const outputDateFormDb = inputDate.toISOString();
        setSelectedDate(localDate);
        setDateFormDb(outputDateFormDb);
        setSelectedShiftAddShiftForm("")
        setSelectedDepartmentEmployee("")
        setSelectedPositionEmployee("")
        setSelectEmployee([])
        setDatePicker([])


        // console.log("Selected date:", localDate);
        // console.log("loclDate", localDate);
        // console.log("dateformDB", dateFormDb);

        setSelectedShift(null)
    };


    const renderTileContent = ({ date }) => {
        return (
            <div className={`font-Changa calendar-tile}`}>
            </div>
        );
    };

    const optionsListDepartment = (checkAdmin === true) ? departList : departmentInhaberOrManager;


    return (
        <div className="ml-[260px] flex flex-col font-Changa text-textColor">
            <div className="p-5 flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">Set shift for multi employee</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl hover:text-black">/ Set Shift</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center w-full gap-4 font-Changa text-textColor">
                <h2 className="text-2xl font-bold">Schedule Calendar</h2>
                {selectedYear && (
                    <Calendar
                        onChange={handleMonthChange}
                        onClickDay={handleClickDay}
                        value={selectedMonth}
                        view="month"
                        showNeighboringMonth={false}
                        tileContent={renderTileContent}
                    />
                )}

                {/* //---------------------------------------------------------------- ADD SHIFT FOR EMPLOYEE ----------------------------------------------------------------// */}
                {FormState && (<div className="fixed top-0 bottom-0 right-0 left-0 z-20 font-Changa">
                    <div
                        onClick={() => setFormState(false)}
                        className="absolute top-0 bottom-0 right-0 left-0 bg-[rgba(0,0,0,.45)] cursor-pointer "></div>
                    <div className="absolute w-[750px] top-0 right-0 bottom-0 z-30 bg-white overflow-y-auto">
                        <div className="w-full h-full">
                            <div className="flex flex-col mt-8">
                                <div className="flex flex-row justify-between px-8 items-center">
                                    <div
                                        onClick={() => setFormState(false)}
                                        className="text-lg border border-solid border-[rgba(0,0,0,.45)] py-1 px-3 rounded-full cursor-pointer">x</div>
                                </div>
                                <div className="w-full border border-solid border-t-[rgba(0,0,0,.45)] mt-4"></div>
                                <div className="flex flex-col px-8 w-full mt-7">
                                    <div
                                        className="flex flex-col gap-6 w-full justify-center items-center"
                                    >
                                        {loading && (<div className="absolute flex w-full h-full items-center justify-center z-10">
                                            <div className="loader_search"></div>
                                        </div>)}
                                        <div className="w-full flex flex-col gap-2">
                                            <div className="flex flex-row gap-2">
                                                <span className="text-rose-500">*</span>
                                                <span className="">Schichtcode</span>
                                            </div>
                                            <select
                                                id="shift_code"
                                                name="shift_code"
                                                className="w-full cursor-pointer rounded-[6px] border-[#d9d9d9]"
                                                value={selectedShiftAddShiftForm}
                                                onChange={(e) => setSelectedShiftAddShiftForm(e.target.value)}
                                                required
                                            >
                                                <option value="" disabled className='italic text-sm'>Schichtcode auswählen*</option>
                                                {shiftList?.map((item, index) => (
                                                    <option className='text-sm text-textColor w-full' key={index} value={item.code}>
                                                        {item.code}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <div className="flex flex-row gap-2">
                                                <span className="text-rose-500">*</span>
                                                <span className="">Filiale</span>
                                            </div>
                                            <select
                                                id="department"
                                                name="department"
                                                className="w-full cursor-pointer rounded-[6px] border-[#d9d9d9]"
                                                value={selectedDepartmentEmployee}
                                                onChange={(e) => setSelectedDepartmentEmployee(e.target.value)}
                                                required
                                            >
                                                <option value="" disabled className='italic text-sm'>Abteilung auswählen*</option>
                                                {optionsListDepartment?.map((item, index) => (
                                                    <option className='text-sm text-textColor w-full' key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <div className="flex flex-row gap-2">
                                                <span className="text-rose-500">*</span>
                                                <span className="">Position</span>
                                            </div>
                                            <select
                                                id="position"
                                                name="position"
                                                className="w-full cursor-pointer rounded-[6px] border-[#d9d9d9]"
                                                value={selectedPositionEmployee}
                                                onChange={(e) => setSelectedPositionEmployee(e.target.value)}
                                                required
                                            >
                                                <option value="" disabled className='italic text-sm'>Position auswählen*</option>
                                                {positionList
                                                    ?.map((each) =>
                                                        <option className='text-sm text-textColor w-full' key={each.name} value={each.name}>
                                                            {each.name}
                                                        </option>
                                                    )}
                                            </select>
                                        </div>

                                        <div className="w-full flex flex-col gap-2">
                                            <div className="flex flex-row gap-2">
                                                <span className="text-rose-500">*</span>
                                                <span className="">Employee</span>
                                            </div>
                                            <Select
                                                isMulti
                                                value={selectEmployee}
                                                onChange={setSelectEmployee}
                                                options={listEmployee}
                                            />

                                        </div>

                                        <div className="w-full h-auto flex flex-col gap-2">
                                            <div className="flex flex-row gap-2">
                                                <span className="text-rose-500">*</span>
                                                <span className="">Dates</span>
                                            </div>
                                            <DatePicker
                                                style={{
                                                    height: '42px',
                                                    width: '100%',
                                                    border: '1px solid #d9d9d9',
                                                    borderRadius: '6px',
                                                }}
                                                format="MM/DD/YYYY"
                                                multiple
                                                value={datePicker}
                                                onChange={setDatePicker}
                                            />
                                        </div>
                                        <div
                                            className=" bg-buttonColor2 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid py-3 rounded-md cursor-pointer hover:bg-emerald-700 w-full">
                                            <button type="button" onClick={handleSubmit} className="w-full">Schicht hinzufügen</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >)}
            </div >

        </div>
    )
}

export default SetMultiShift;