import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import "./Salary.css"
import * as XLSX from "xlsx";
import { message } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Space } from 'antd';
import { baseUrl } from "components/api/httpService";
import { Pagination } from 'antd';
dayjs.extend(customParseFormat);
const monthFormat = 'MM/YYYY';

const SalarySummarizie = () => {
    document.title = 'Salary Summarization'
    const monthFormat = "MM/YYYY";
    const [inputMonth, setInputMonth] = useState("")
    const [inputYear, setInputYear] = useState("")
    const [inputId, setInputId] = useState("")
    const [inputName, setInputName] = useState("")
    const [salaryListByMonth, setSalaryListByMonth] = useState()
    const [monthPicker, setMonthPicker] = useState(dayjs())
    const [monthCountingPikcer, setMonthCountingPikcer] = useState("")
    const [variableCountingSalary, setVariableCountingSalary] = useState()

    const userString = localStorage.getItem('user');
    const userObject = userString ? JSON.parse(userString) : null;

    const [salaryCountingFormState, setSalaryCountingFormState] = useState(false)
    const [editSalaryCountingFormState, setEditSalaryCountingFormState] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingStartPage, setLoadingStartPage] = useState(true)
    const [loadingSearching, setLoadingSearching] = useState(false)
    const [exportEmployee, setExportEmployee] = useState(false)
    const [checkManager, setCheckManager] = useState(false)
    const [selectedUserName, setSelectedUserName] = useState("")
    const [userList, setUserList] = useState()
    const [userListSearch, setUserListSearch] = useState()
    const [checkLockSalary, setCheckLockSalary] = useState(false)
    const [checkAdmin, setCheckAdmin] = useState(false)

    // const PAGE_SIZE = 50
    // const [currentPage, setCurrentPage] = useState(1);
    // const indexOfLastItem = currentPage * PAGE_SIZE;
    // const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
    // const currentUsers = salaryListByMonth?.slice(indexOfFirstItem, indexOfLastItem);
    // const totalPages = Math.ceil(salaryListByMonth?.length / PAGE_SIZE);
    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };

    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentUsers = salaryListByMonth?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(salaryListByMonth?.length / pageSize);

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleMonthChange = (date) => {
        setMonthPicker(date)
    };

    const handleMonthCountingChange = (date, dateString) => {
        setMonthCountingPikcer(dateString)
    }

    const handleGetInMonth = async () => {
        let role = userObject.role
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        let currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        let api = `${baseUrl}/api/` + (userObject?.role).toLowerCase() + `/manage-salary/get-summarize?year=${currentYear}&month=${currentMonth}`
        if (role === "Inhaber") {
            api += `&inhaber_name=${userObject?.name}`
        }
        try {
            const { data } = await axios.get(
                api,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            setSalaryListByMonth(data?.message)
            setLoadingStartPage(false);
        } catch (err) {
            alert("No salary recorded")
            setLoadingStartPage(false);

        }
    }


    useEffect(() => {
        if (userObject?.role === 'Admin') {
            setCheckAdmin(true)
            setCheckManager(false)
        }
        else if (userObject?.role === 'Manager') {
            setCheckManager(true)
            setCheckAdmin(false)
        }
    }, [userObject?.role]);

    useEffect(() => {
        handleGetInMonth()

        const checkLockSalary = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/admin/manage-salary/check-calculate`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                setCheckLockSalary(response.data.value);
            } catch (err) {
                alert(err.response?.data?.message)
            }
        }

        checkLockSalary()
    }, [])


    const handleSeacrh = async () => {
        setLoadingSearching(true)
        let role = userObject.role
        const formattedDate = monthPicker.format("MM/YYYY"); // Chuyển thành chuỗi "MM/YYYY"
        const month = formattedDate.substring(0, 2); // Lấy tháng (2 ký tự đầu tiên)
        const year = formattedDate.substring(3, 7);
        let urlApi = `${baseUrl}/api/${role}/manage-salary/get-summarize?year=${year}&month=${month}&employeeName=${inputName}&employeeID=${inputId}`
        if (role === "Inhaber") {
            urlApi += `&inhaber_name=${userObject?.name}`
        }
        try {
            const { data } = await axios.get(
                urlApi,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            setSalaryListByMonth(data?.message)
            setLoadingSearching(false)

        } catch (err) {
            alert("No salary recorded")
            setLoadingSearching(false)

        }
    }

    const [formData, setFormData] = useState({
        user: {
            id: '',
            month: '',
            year: '',
            a: '0',
            b: '0',
            c: '0',
            d: '0.25',
            f: '0',
            g: '0',
            h: '0',
            k: '0',
        },
    });

    const convertCommaStringToNumber = (str) => {
        // Check if the input is a string and not empty
        if (typeof str !== 'string' || !str.trim()) {
            console.error("Invalid input: Not a string or empty", str);
            return str; // Handle invalid input as needed
        }

        // Replace all commas with dots
        const dotString = str.replace(/,/g, '.');

        // Convert to a float
        const number = parseFloat(dotString);

        // Check if the conversion was successful (NaN check)
        if (isNaN(number)) {
            console.error("Invalid number format:", str);
            return null; // Handle invalid number format as needed
        }

        return number;
    };


    const getUserList = async () => {
        if (formData?.user?.id === "") {
            alert("please enter search id")
        } else {
            setUserList([])
            setLoading(true)
            if (userObject.role === 'Admin') {
                try {
                    const { data } = await axios.get(
                        `${baseUrl}/api/admin/manage-all/search-specific?details=${formData?.user?.id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    );
                    setUserList(data?.message)
                } catch (err) {
                    console.log(err)
                }
            }
            else if (userObject.role === 'Inhaber') {
                try {
                    const { data } = await axios.get(
                        `${baseUrl}/api/inhaber/manage-employee/search-specific?inhaber_name=${userObject?.name}&details=${formData?.user?.id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    );
                    setUserList(data?.message)
                } catch (err) {
                    console.log(err)
                }
            }
            setLoading(false)

        }

    }


    useEffect(() => {
        const getUseVariableCoutingSalary = async () => {
            if (formData?.user?.id && selectedUserName && monthCountingPikcer) {
                if (userObject?.role == 'Admin') {
                    try {
                        const { data } = await axios.get(
                            `${baseUrl}/api/admin/manage-salary/get-latest?employeeID=${formData?.user?.id}&employeeName=${selectedUserName}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`
                                }
                            }
                        );
                        setVariableCountingSalary(data?.message)
                    } catch (err) {
                        alert(err.response?.data?.message)
                    }
                }
                if (userObject?.role == 'Inhaber') {
                    try {
                        const { data } = await axios.get(
                            `${baseUrl}/api/inhaber/manage-salary/get-latest?employeeID=${formData?.user?.id}&employeeName=${selectedUserName}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`
                                }
                            }
                        );
                        setVariableCountingSalary(data?.message)
                    } catch (err) {
                        alert(err.response?.data?.message)
                    }
                }
            }
        }
        getUseVariableCoutingSalary()
    }, [formData?.user?.id, selectedUserName, monthCountingPikcer])

    // console.log(variableCountingSalary);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            user: {
                ...prevData.user,
                [name]: value,
            },
        }));
    };

    useEffect(() => {
        if (variableCountingSalary) {
            setFormData({
                user: {
                    ...formData.user,
                    a: variableCountingSalary?.a_parameter,
                    b: variableCountingSalary?.b_parameter,
                    c: variableCountingSalary?.c_parameter,
                    d: variableCountingSalary?.d_parameter,
                    f: variableCountingSalary?.f_parameter,
                    g: variableCountingSalary?.g_parameter,
                    h: variableCountingSalary?.h_parameter,
                    k: variableCountingSalary?.k_parameter
                }
            })
        }
    }, [variableCountingSalary])

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true)
        const salaryCounting = async () => {
            if (userObject?.role === "Admin") {
                try {
                    const { data } = await axios.post(
                        `${baseUrl}/api/admin/manage-salary/calculate/${formData.user.id}?employeeName=${selectedUserName}&year=${monthCountingPikcer.substring(3, 7)}&month=${monthCountingPikcer.substring(0, 2)}`,
                        {
                            a_new: convertCommaStringToNumber(formData.user.a),
                            b_new: convertCommaStringToNumber(formData.user.b),
                            c_new: convertCommaStringToNumber(formData.user.c),
                            d_new: convertCommaStringToNumber(formData.user.d),
                            f_new: convertCommaStringToNumber(formData.user.f),
                            g_new: convertCommaStringToNumber(formData.user.g),
                            h_new: convertCommaStringToNumber(formData.user.h),
                            k_new: convertCommaStringToNumber(formData.user.k)
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    );
                    setLoading(false);
                    setFormData({
                        user: {
                            id: '',
                            a: '0',
                            b: '0',
                            c: '0',
                            d: '0.25',
                            f: '0',
                            g: '0',
                            h: '0',
                            k: '0'
                        },
                    });
                    setSelectedUserName("")
                    setMonthCountingPikcer("")
                    setSalaryCountingFormState(false)
                } catch (err) {
                    console.log(err);
                    alert(err.response?.data?.message)
                    setLoading(false)
                } finally {
                    handleSeacrh()
                }
            }

            if (userObject?.role === "Inhaber") {
                try {
                    const { data } = await axios.post(
                        `${baseUrl}/api/inhaber/manage-salary/calculate/${formData.user.id}?employeeName=${selectedUserName}&year=${monthCountingPikcer.substring(3, 7)}&month=${monthCountingPikcer.substring(0, 2)}`,
                        {
                            a_new: convertCommaStringToNumber(formData.user.a),
                            b_new: convertCommaStringToNumber(formData.user.b),
                            c_new: convertCommaStringToNumber(formData.user.c),
                            d_new: convertCommaStringToNumber(formData.user.d),
                            f_new: convertCommaStringToNumber(formData.user.f),
                            g_new: convertCommaStringToNumber(formData.user.g),
                            h_new: convertCommaStringToNumber(formData.user.h),
                            k_new: convertCommaStringToNumber(formData.user.k)
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        }
                    );
                    setLoading(false);
                    setFormData({
                        user: {
                            id: '',
                            a: '',
                            b: '',
                            c: '',
                            d: '0.25',
                            f: '',
                            g: '',
                            h: '',
                            k: ''
                        },
                    });
                    setSelectedUserName("")
                    setMonthCountingPikcer("")
                    setSalaryCountingFormState(false)
                } catch (err) {
                    // Handle error
                    alert(err.response?.data?.message);
                    setLoading(false)
                } finally {
                    handleSeacrh()
                }
            }
        };
        salaryCounting()
    }
    const handleExportSalaryByEmloyeeFile = async () => {
        setLoading(true);
        if (userObject?.role === "Admin") {
            try {
                setLoading(true);
                const { data } = await axios.get(
                    `${baseUrl}/api/admin/manage-xlsx/salary-data?year=${monthPicker.substring(3, 7)}&month=${monthPicker.substring(0, 2)}&employeeName=${inputName}`,
                    {
                        responseType: "arraybuffer", headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                );

                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(blob);
                link.download = `Employee_Salary_Data_${monthPicker.substring(0, 2)}_${monthPicker.substring(3, 7)}.xlsx`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                // console.error("Error exporting Excel file:", error);
            } finally {
                setLoading(false);
                setExportEmployee(false)
            }
        }
        if (userObject?.role === "Inhaber") {
            try {
                setLoading(true);
                const { data } = await axios.get(
                    `${baseUrl}/api/inhaber/manage-xlsx/salary-data?inhaberName=${userObject?.name}&year=${monthPicker.substring(3, 7)}&month=${monthPicker.substring(0, 2)}&employeeName=${inputName}`,
                    {
                        responseType: "arraybuffer", headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                );

                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const link = document.createElement("a");

                link.href = window.URL.createObjectURL(blob);
                link.download = `Employee_Salary_Data_${monthPicker.substring(0, 2)}_${monthPicker.substring(3, 7)}.xlsx`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error("Error exporting Excel file:", error);
            } finally {
                setLoading(false);
                setExportEmployee(false)
            }
        }
    }

    const handleCancelFormCountingSalary = () => {
        setSalaryCountingFormState(false)
        setFormData({
            user: {
                id: '',
                month: '',
                year: '',
                a: '0',
                b: '0',
                c: '0',
                d: '0.25',
                f: '0',
                g: '0',
                h: '0',
                k: '0'
            },
        })
        setSelectedUserName("")
    }

    const handleExport = () => {
        setExportEmployee(!exportEmployee)
    }

    const handleLockSalary = async (status) => {
        let urlApi = `${baseUrl}/api/admin/manage-salary/`
        if (status) {
            urlApi += "unlock-calculate-salary"
        } else {
            urlApi += "lock-calculate-salary"
        }

        try {
            const response = await axios.post(urlApi,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            if (response.status === 200) {
                setCheckLockSalary(status);
            }
        } catch (err) {
            alert(err.response?.data?.message)
        }
    }
    return (
        <div>
            {checkManager ? (<div className="ml-[260px] h-auto p-5 flex flex-col font-Changa text-textColor gap-5">YOU CANNOT ACCESS THIS ROUTE</div>) :
                (loadingStartPage ? (<div class="w-full h-screen flex items-center justify-center">
                    <div class="w-16 h-16 border-4 border-t-4 border-gray-200 rounded-full animate-spin border-t-blue-500"></div>
                </div>) : (<div className="relative ml-[260px] h-auto p-5 flex flex-col font-Changa text-textColor gap-5">
                    <div className="flex flex-row items-center justify-between">
                        <div>
                            <h1 className="font-bold text-3xl">Salary Summarize</h1>
                            <div className="flex flex-row">
                                <Link className="text-xl font-semibold leading-6 hover:underline" to="/dashboard">Dashboard</Link>
                                <span className="text-[#6c757d] font-xl">/ Salary</span>
                                <Link className="text-[#6c757d] font-xl leading-6 hover:underline" to="/salary/summarize">/ Salary Summarize</Link>
                            </div>
                        </div>
                        <div className="flex flex-row px-4 gap-4">
                            {checkAdmin && (
                                <button
                                    onClick={() => handleLockSalary(!checkLockSalary)}
                                    className="bg-red-500 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md hover:bg-red-600"
                                >
                                    {checkLockSalary ? "Lock" : "UnLock"}
                                </button>

                            )}
                            <button onClick={() => setSalaryCountingFormState(!salaryCountingFormState)} className="bg-buttonColor2 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md hover:bg-emerald-800">
                                <svg style={{ width: '14px', height: '16px' }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>
                                Gehaltszählung
                            </button>
                            <button onClick={handleExport} className="bg-buttonColor1 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md hover:bg-cyan-800">
                                <svg style={{ width: '14px', height: '16px' }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>
                                Exportdatei
                            </button>
                        </div>
                    </div>
                    <div className="border border-solid border-t-[#6c757d]"></div>

                    <div className="z-10 flex flex-row mt-10 justify-between h-[50px]">
                        <div className="flex flex-row gap-20 w-3/5">
                            <Space className="w-1/3 text-[#6c757d]" direction="vertical" size={12}>
                                <DatePicker
                                    value={monthPicker} // Gán giá trị ban đầu
                                    onChange={handleMonthChange}
                                    className="w-full h-[50px] text-base text-placeholderTextColor"
                                    format={monthFormat}
                                    picker="month"
                                />
                            </Space>

                            <input
                                className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] w-1/3 text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                type="text"
                                placeholder="Enter Name"
                                value={inputName}
                                onChange={(e) => setInputName(e.target.value)}
                            />

                            <input
                                className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] w-1/3 text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                type="text"
                                placeholder="Enter Id"
                                value={inputId}
                                onChange={(e) => setInputId(e.target.value)}
                            />


                            {/* <div className="w-2/3 flex flex-col gap-2 h-[50px]">
                            <select
                                id="name_search"
                                name="name_search"
                                className="w-full cursor-pointer h-[50px] border-[#d9d9d9] rounded-[6px] text-[#6c757d] hover:border-[#4096ff] focus:border-[#4096ff]"
                                value={inputName}
                                onChange={(e) => setInputName(e.target.value)}
                            // required
                            >
                                <option value="" disabled className='italic text-sm'>Select Employee Name*</option>
                                {userListSearch?.map((item, index) => (
                                    <option className='text-sm text-[#6c757d] w-full' key={index} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        </div>
                        <div
                            onClick={handleSeacrh}
                            className="bg-buttonColor2 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md cursor-pointer hover:bg-emerald-700 w-1/6">
                            <button className="search-btn">Suchen</button>
                        </div>
                    </div>

                    {loadingSearching ? (<div className="flex w-full h-full items-center justify-center">
                        <div className="loader_search"></div>
                    </div>) :
                        (
                            <>
                                <div className="block w-full text-base font-Changa mt-5 overflow-y-scroll overflow-x-scroll">
                                    <table className="w-full table">
                                        <thead className="">
                                            <tr className="">
                                                <th className="p-2 text-left">
                                                    <span className="font-bold">Name</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-id">Employee ID</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">ARBEITSZEIT</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">Total funktionsfähig</span>
                                                </th>
                                                {/* <th className="p-2 text-left">
                                    <span className="table-title-status">Im Laufe der Zeit</span>
                                </th> */}
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">netto</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">überweisung</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">vorschuss</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">€/km (0,25)</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">über s x</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">bonus</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">tip</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">optional</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">Total Km</span>
                                                </th>
                                                <th className="p-2 text-left">
                                                    <span className="table-title-status">Gehalt</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        {Array.isArray(currentUsers) && currentUsers?.length === 0 ? (
                                            <div className="no-result-text text-center">NO RESULT</div>
                                        ) : (
                                            <tbody className="tbody">
                                                {currentUsers?.map(({ employee_id, employee_name, hour_normal, total_hour_work, total_hour_overtime, a_parameter, b_parameter, c_parameter, d_parameter, f_parameter, g_parameter, h_parameter, k_parameter, total_km, total_salary }, index) => (
                                                    <tr className="tr-item" key={index}>
                                                        <td className="p-2 hover:text-buttonColor2">
                                                            <h2 className="text-left">
                                                                <Link className="cursor-pointer flex flex-col" to={`/salary/sumarize/${employee_id}/${employee_name}`}>{employee_name}
                                                                </Link>
                                                            </h2>
                                                        </td>
                                                        <td className="p-2">{employee_id}</td>
                                                        <td className="p-2">
                                                            <div className="flex flex-col">
                                                                {hour_normal?.map(({ department_name, total_hour, total_minutes }) => (
                                                                    <div className="flex flex-row gap-3">
                                                                        <span>{department_name}: {total_hour}h {total_minutes}m</span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                        <td className="p-2">{total_hour_work}</td>
                                                        {/* <td className="p-2">{total_hour_overtime}</td> */}
                                                        <td className="p-2">{a_parameter}</td>
                                                        <td className="p-2">{b_parameter}</td>
                                                        <td className="p-2">{c_parameter}</td>
                                                        <td className="p-2">{d_parameter}</td>
                                                        <td className="p-2">{f_parameter}</td>
                                                        <td className="p-2">{g_parameter}</td>
                                                        <td className="p-2">{h_parameter}</td>
                                                        <td className="p-2">{k_parameter}</td>
                                                        <td className="p-2">{total_km}</td>
                                                        <td className="p-2">{total_salary}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div className="flex flex-wrap gap-2 justify-center items-center mt-4 mb-4">
                                    <Pagination
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={salaryListByMonth?.length}
                                        onChange={handlePageChange}
                                        className="text-base"
                                    />
                                </div>
                            </>
                        )
                    }
                    {salaryCountingFormState && (<div className="fixed top-0 bottom-0 right-0 left-0 z-20 font-Changa">
                        <div
                            onClick={() => setSalaryCountingFormState(false)}
                            className="absolute top-0 bottom-0 right-0 left-0 bg-[rgba(0,0,0,.45)] cursor-pointer"></div>
                        <div className="absolute w-[500px] top-0 right-0 bottom-0 z-30 bg-white overflow-y-auto">
                            <div className="w-full h-full">
                                <div className="flex flex-col mt-8">
                                    <div className="flex flex-row justify-between px-8 items-center">
                                        <div className="font-bold text-xl">Gehaltszählung</div>
                                        <div
                                            onClick={handleCancelFormCountingSalary}
                                            // onClick={() => setSalaryCountingFormState(false)}
                                            className="text-lg border border-solid border-[rgba(0,0,0,.45)] py-1 px-3 rounded-full cursor-pointer">x</div>
                                    </div>
                                    <div className="w-full border border-solid border-t-[rgba(0,0,0,.45)] mt-4"></div>
                                    <div className="flex flex-col px-8 w-full mt-7">
                                        <form
                                            className="flex flex-col gap-6 w-full justify-center items-center"
                                            onSubmit={handleSubmit}>
                                            {loading && (<div className="absolute flex w-full h-full items-center justify-center z-10">
                                                <div className="loader_auto"></div>
                                            </div>)}
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    <span className="text-rose-500">*</span>
                                                    <span className="">Employee's ID</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    name="id"
                                                    required
                                                    value={formData.user.id}
                                                    onChange={handleChange}
                                                    onBlur={getUserList}
                                                />
                                            </div>
                                            <div className="w-full flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    <span className="text-rose-500">*</span>
                                                    <span className="">Employee's Name</span>
                                                </div>
                                                <select
                                                    id="name"
                                                    name="name"
                                                    className="cursor-pointer border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    value={selectedUserName}
                                                    onChange={(e) => setSelectedUserName(e.target.value)}
                                                // required
                                                >
                                                    <option value="" disabled className='italic text-sm'>Select Employee Name*</option>
                                                    {userList?.map((item, index) => (
                                                        <option className='text-sm text-textColor w-full' key={index} value={item.name}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    <span className="text-rose-500">*</span>
                                                    <span className="">Month</span>
                                                </div>
                                                <Space className="w-full text-[#6c757d] font-Changa" direction="vertical" size={12}>
                                                    <DatePicker required onChange={handleMonthCountingChange} placeholder="Select Month" className="placeholder:text-sm placeholder:text-placeholderTextColor w-full h-[45px] text-base text-placeholderTextColor" format={monthFormat} picker="month" />
                                                </Space>
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">netto</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="a"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.a}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">überweisung</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="b"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.b}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">vorschuss</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="c"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.c}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">€/km (0,25)</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="d"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.d}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">über s x</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="f"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.f}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">bonus</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="g"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.g}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">tip</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="h"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.h}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div className="w-full h-auto flex flex-col gap-2">
                                                <div className="flex flex-row gap-2">
                                                    {/* <span className="text-rose-500">*</span> */}
                                                    <span className="">optional</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="k"
                                                    className="border-[#d9d9d9] text-[#6c757d] rounded-[6px] h-[45px] w-full text-base px-4 py-3 placeholder:text-placeholderTextColor hover:border-[#4096ff] focus:border-[#4096ff]"
                                                    // required
                                                    value={formData.user.k}
                                                    onChange={handleChange}
                                                    placeholder="12,34"
                                                />
                                            </div>
                                            <div
                                                className=" bg-buttonColor2 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid py-3 rounded-md cursor-pointer hover:bg-emerald-700 w-full">
                                                <button type="submit" className="w-full">Gehaltszählung</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                    {exportEmployee && (<div className="fixed top-0 bottom-0 right-0 left-0 z-20 font-Changa">
                        <div
                            onClick={() => setExportEmployee(false)}
                            className="absolute top-0 bottom-0 right-0 left-0 bg-[rgba(0,0,0,.45)] cursor-pointer"></div>
                        <div className="absolute w-[700px] h-[200px] top-[300px] right-[500px] bottom-0 z-30 bg-white">
                            <div className="w-full h-full">
                                <div className="flex flex-col mt-8">
                                    <div className="flex flex-row justify-between px-8 items-center">
                                        <div className="font-bold text-xl">Export file</div>
                                        <div
                                            onClick={() => setExportEmployee(false)}
                                            className="text-lg border border-solid border-[rgba(0,0,0,.45)] py-1 px-3 rounded-full cursor-pointer">x</div>
                                    </div>
                                    <div className="w-full border border-solid border-t-[rgba(0,0,0,.45)] mt-4"></div>
                                    <div className="flex flex-col px-8 w-full mt-7 font-Changa justify-center items-center gap-4">
                                        <span>Möchten Sie exportieren Employee_Salary_Data_{monthPicker.substring(0, 2)}_{monthPicker.substring(3, 7)}.xlsx?</span>
                                        <div className="flex flex-row gap-3">
                                            <button onClick={() => setExportEmployee(false)} type="button" className="w-[100px] bg-rose-800 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid px-2 py-1 rounded-md cursor-pointe">No</button>
                                            <button onClick={handleExportSalaryByEmloyeeFile} type="button" className="w-[100px] bg-buttonColor2 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid px-2 py-1 rounded-md cursor-pointer">Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>)
                )
            }
        </div>

    )
}

export default SalarySummarizie;