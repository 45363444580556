import { Routes, Route } from "react-router-dom";

import Dashboard from "views/Dashboard/Dashboard";
import Attendance from "views/Attendance/Attendance";
import Employee from "views/Employee/Employee";
import Department from "views/Employee/Department/Department";
import Position from "views/Position/Position";
import ProfileEmployee from "views/ProfileEmployee/ProfileEmployee";
import WorkingSchedule from "views/Attendance/WorkingSchedule/WorkingSchedule";
// import AddEmployee from "views/Employee/AddEmployee/AddEmployee";
import Login from "views/Login/Login";
// import { AuthContextProvider } from "context/AuthContext";
import Layout from "components/Layout";
import Unauthorized from "components/Unauthorized";
import Missing from "components/Missing";
import DayOffManagement from "views/Attendance/DayOffManagement/DayOffManagement";
import SalarySummarize from "views/Salary/SalarySummarize";
import SalaryEmployee from "views/Salary/SalaryEmployee";
import ManageLog from "views/Attendance/MangeLog/ManageLog";
import Car from "views/Car/Car";
import ReportForm from "views/ReportForm/ReportForm";
import GenerateQR from "views/GenerateQR/GenerateQR";
import History from "views/Salary/History";
import QrLink from "views/GenerateQR/QrLink";
import SetMultiShift from "views/Attendance/SetMultiShift"
import ConfirmResertPassword from "views/Auth/ConfirmResertPassword"
import ForgotPassword from "views/Auth/ForgotPassword "
import ChangePassword from "views/Auth/ChangePassword ";
import AddRecipeCaculerSalary from "views/Salary/AddRecipeCaculerSalary"
import ListRecipe from "views/Salary/ListRecipe"
import EmployeeRequestList from "views/Employee/EmployeeRequest";
import EmployeeRequestDetail from "views/Employee/EmployeeRequestDetail";
import EditRecipeCalculatorSalary from "views/Salary/EditRecipeCaculerSalary";

import ListRecipeLito from "views/Recipe/ListRecipeLito";
import AddRecipeLito from "views/Recipe/AddRecipeLito";
import EditRecipeLito from "views/Recipe/EditRecipeLito";

import ListRecipeService from "views/Recipe/ListRecipeService";
import AddrecipeService from "views/Recipe/AddRecipeService";
import EditrecipeService from "views/Recipe/EditRecipeService";
// const titles = {
//     '/': 'QR Checkin',
//     '/attendance': 'Attendance',
//     '/employee': 'Employee',
// }

const Router = () => {
    // const location = useLocation()
    // useEffect(
    //     async () => (document.title = titles[location.pathname]),
    //     [location],
    // )  
    return (
        <Routes>
            <Route path="/" element={<Layout />} >
                {/* public routes */}
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="change-pass" element={<ChangePassword />} />

        
                {/* protected routes */}
                {/* <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Inhaber]}/>} > */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="attendance" element={<Attendance />} />
                <Route path="employee" element={<Employee />} />
                <Route path="employeeRequest" element={<EmployeeRequestList />} />
                <Route path="employeeRequestDetail/:id" element={<EmployeeRequestDetail />} />
                <Route path="employee/setCalendar" element={<SetMultiShift />} />
                <Route path="employee/departments" element={<Department />} />
                <Route path="employee/position" element={<Position />} />
                <Route path="employee/view-profile/:id/:name" element={<ProfileEmployee />} />
                <Route path="working-schedule" element={<WorkingSchedule />} />
                <Route path="working-schedule/day-off-management" element={<DayOffManagement />} />
                <Route path="salary/summarize" element={<SalarySummarize />} />
                <Route path="salary/history_counting" element={<History />} />
                <Route path="salary/sumarize/:employeeId/:employeeName" element={<SalaryEmployee />} />
                <Route path="manage-log" element={<ManageLog />} />
                <Route path="manage-car" element={<Car />} />
                <Route path="manage-report-form" element={<ReportForm />} />
                {/* <Route path="attendance/summarize" element={<AttendanceHistory />} /> */}
                <Route path="generate_qr" element={<GenerateQR />} />
                <Route path="Qr_link/:selectedDepartment" element={<QrLink />} />
                <Route path="add-recipe-salary" element={<AddRecipeCaculerSalary />} />
                <Route path="list-recipe-salary" element={<ListRecipe />} />
                <Route path="edit-recipe-salary/:id" element={<EditRecipeCalculatorSalary />} />

                <Route path="add-recipe-lito" element={<AddRecipeLito />} />
                <Route path="list-recipe-lito" element={<ListRecipeLito />} />
                <Route path="edit-recipe-lito/:id" element={<EditRecipeLito />} />

                <Route path="add-recipe-service" element={<AddrecipeService />} />
                <Route path="list-recipe-service" element={<ListRecipeService />} />
                <Route path="edit-recipe-service/:id" element={<EditrecipeService />} />
                {/* <Route path="employee/add-employee" element={<AddEmployee />} /> */}
                {/* </Route> */}

                {/* missing route */}
                <Route path="*" element={<Missing />} />

            </Route>
            <Route path="/reset-password" element={ <ConfirmResertPassword /> }/>
            <Route path="/fogot-password" element={ <ForgotPassword /> }/>
        </Routes>

    );
};

export default Router;

