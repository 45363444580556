import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from "components/api/httpService";
const EmployeeRequestList = () => {
    const [employees, setEmployees] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [nameFilter, setNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('pending');
    const [loadingSearching, setLoadingSearching] = useState(false);


    useEffect(() => {
        fetchEmployees();
    }, [page, limit]);

    const fetchEmployees = async () => {
        setLoadingSearching(true)
        try {
            const response = await axios.get(`${baseUrl}/api/crm/get-employee-request`, {
                params: {
                    page,
                    limit,
                    name: nameFilter,
                    status: statusFilter,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setEmployees(response.data.employees);
            setTotalEmployees(response.data.total);
            setLoadingSearching(false)
        } catch (error) {
            setLoadingSearching(false)
            console.error("Error fetching employees:", error);
        }
    };

    const handleSeacrh = async () => {
        fetchEmployees()
    }

    const handleStatusChange = async (id) => {
        if (window.confirm("Do you want to change the status to 'handle'?")) {
            try {
                await axios.put(`${baseUrl}/api/crm/set-employee-status/${id}`,
                    { status: "handle" }, // Đây là dữ liệu body
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                );

                fetchEmployees(); // Refresh the list after status change
            } catch (error) {
                alert("Error updating employee status:", error);
            }
        }
    };

    return (
        <div className="relative ml-[260px] h-auto p-5 flex flex-col font-Changa text-textColor gap-5 justify-center">
            <div className="flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">Employees Request</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/dashboard">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl">/ Employees request</span>
                    </div>
                </div>
            </div>
            <div className="border border-solid border-t-[#6c757d]"></div>

            {/* //---------------------------------------------------------------- SEARCH ----------------------------------------------------------------// */}
            <div className="z-10 flex flex-row mt-10 justify-between h-[50px]">

                <input
                    type="text"
                    className="w-1/4 border-[#d9d9d9] text-[#6c757d] rounded-[6px] hover:border-[#4096ff] focus:border-[#4096ff] placeholder:text-placeholderTextColorw-1/4 text-base px-4 py-3 placeholder:text-placeholderTextColor"

                    placeholder="Search by name"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                />
                <select
                    className="w-1/4 border-[#d9d9d9] text-[#6c757d] rounded-[6px] hover:border-[#4096ff] focus:border-[#4096ff] text-base px-4 py-3 placeholder:text-placeholderTextColor"
                    value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                    <option value="pending">Pending</option>
                    <option value="handle">Handle</option>
                </select>


                <div
                    onClick={handleSeacrh}
                    className="bg-buttonColor2 text-white text-base flex flex-row gap-1 justify-center items-center border border-solid p-2 rounded-md cursor-pointer hover:bg-emerald-700 w-1/6">
                    <button className="search-btn">Suchen</button>
                </div>
            </div>

            {/* //---------------------------------------------------------------- USER LIST ----------------------------------------------------------------// */}
            <div className="block w-full text-base font-Changa mt-5 overflow-y-scroll overflow-x-scroll">
                <table className="w-full table">
                    <thead className="">
                        <tr className="">
                            <th className="p-2 text-left">
                                <span className="font-bold">Name</span>
                            </th>
                            <th className="p-2 text-left">
                                <span className="font-bold">Surname</span>
                            </th>
                            <th className="p-2 text-left">
                                <span className="font-bold">Email</span>
                            </th>
                            <th className="p-2 text-left">
                                <span className="font-bold">Status</span>
                            </th>
                            <th className="p-2 text-center">
                                <span className="font-bold">Action</span>
                            </th>
                        </tr>
                    </thead>
                    {Array.isArray(employees) && employees?.length === 0 ? (
                        <div className="no-result-text text-center">NO RESULT</div>
                    ) : (
                        <tbody className="tbody">
                            {employees.map((employee) => (
                                <tr key={employee._id}>
                                    <td>{employee.name}</td>
                                    <td>{employee.surname}</td>
                                    <td>{employee.email}</td>
                                    <td>{employee.status}</td>
                                    <td>
                                        <div className="flex justify-center items-center space-x-4 my-2">
                                            <Link
                                                to={`/employeeRequestDetail/${employee._id}`}
                                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                                View Details
                                            </Link>

                                            {employee.status === "pending" && (
                                                <button
                                                    onClick={() => handleStatusChange(employee._id)}
                                                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                                                    Change Status
                                                </button>
                                            )}
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </tbody>)}
                </table>
            </div>
            {loadingSearching && (<div className="flex w-full h-full items-center justify-center">
                <div className="loader_search"></div>
            </div>)}

            <div className="flex flex-wrap gap-2 justify-center items-center mt-4 mb-4">
                {[...Array(Math.ceil(totalEmployees / limit)).keys()].map((number) => (
                    <button
                        key={number}
                        onClick={() => setPage(number + 1)}
                        className={`px-4 py-2 mx-1 border border-gray-300 rounded-md 
                    ${page === number + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-100'}`}>
                        {number + 1}
                    </button>
                ))}

            </div>
        </div>
    );
};

export default EmployeeRequestList;