import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "components/api/httpService";

const EditrecipeService = () => {
    const { id } = useParams();
    const [name, setName] = useState("");
    const [thC2Condition, setthC2Condition] = useState("");
    const [thC2ResCondition, setthC2ResCondition] = useState("");
    const [thC2UnResCondition, setthC2UnResCondition] = useState("");
    const [thC6Contiditon, setThC6Contiditon] = useState("");
    const [thC6ResCondition, setThC6ResCondition] = useState("");
    const [thC6UnResCondition, setThC6UnResCondition] = useState("");
    const [thElseCondition, setThElseCondition] = useState("");
    const [thElseResCondition, setThElseResCondition] = useState("");
    const [thElseUnResCondition, setThElseUnResCondition] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/crm/recipeService/getById/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const recipe = response.data;
                setName(recipe.name);
                setthC2Condition(recipe.thC2Condition);
                setthC2ResCondition(recipe.thC2ResCondition);
                setthC2UnResCondition(recipe.thC2UnResCondition);
                setThC6Contiditon(recipe.thC6Contiditon);
                setThC6ResCondition(recipe.thC6ResCondition);
                setThC6UnResCondition(recipe.thC6UnResCondition);
                setThElseCondition(recipe.thElseCondition);
                setThElseResCondition(recipe.thElseResCondition);
                setThElseUnResCondition(recipe.thElseUnResCondition);
            } catch (err) {
                console.error("Error fetching recipe:", err);
            }
        };

        fetchRecipe();
    }, [id]);

    const handleSubmit = async () => {
        setError(""); // Reset error trước khi submit
        try {
            await axios.put(`${baseUrl}/api/crm/recipeService/update/${id}`, {
                name,
                thC2Condition,
                thC2ResCondition,
                thC2UnResCondition,
                thC6Contiditon,
                thC6ResCondition,
                thC6UnResCondition,
                thElseCondition,
                thElseResCondition,
                thElseUnResCondition,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            navigate("/list-recipe-Service"); // Quay về trang danh sách nếu thành công
        } catch (err) {
            const errorMessage = err.response?.data?.message || "Có lỗi xảy ra. Vui lòng kiểm tra lại.";
            setError(errorMessage); // Thông báo lỗi từ server
        }
    };

    return (
        <div className="ml-[260px] flex flex-col font-Changa text-textColor">
            <div className="p-5 flex flex-row items-center justify-between">
                <div>
                    <h1 className="font-bold text-3xl">Edit Recipe Service</h1>
                    <div className="flex flex-row">
                        <Link className="text-xl font-semibold leading-6 hover:underline" to="/">Dashboard</Link>
                        <span className="text-[#6c757d] font-xl hover:text-black">/ edit recipe Service</span>
                    </div>
                </div>
            </div>

            {/* Thêm thông báo lưu ý */}
            <div className="p-4">
                <p className="text-red-500 text-center italic mb-4">
                    Bitte geben Sie nur geeignete Variablen und Operationen wie +,-,*,/,&gt;=,&lt;=,&gt;,&lt;,(,) ein. Beachten Sie, dass, wenn das Gleichheitszeichen (=) als == eingegeben wird, andernfalls (#) als != eingegeben wird
                </p>
                <p className="text-red-500 text-center italic mb-4">
                    Die importierten Variablen sind: bar, gesamt, trinked_ec, trink_geld, auf_rechnung
                </p>
                <p className="text-red-500 text-center italic mb-4">
                    Wenn Sie „Condition“ nicht eingeben, ist die Eingabe von „Unresolved Condition“ nicht erforderlich
                </p>

            </div>

            <div className="container mx-auto p-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />

                <h2 className="font-bold mt-4 mb-2">Fall : Abteilungen C2</h2>
                <label className="block text-gray-700 text-sm font-bold mb-1">Condition <span className="text-red-500 italic mb-4">Example bar + gesamt &gt; 1000 </span></label>

                <textarea
                    value={thC2Condition}
                    onChange={(e) => setthC2Condition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mb-1">Result Condition <span className="text-red-500 italic mb-4">Example bar + gesamt - trinked_ec - (0,5/100) * (trink_geld + auf_rechnung) </span></label>
                <textarea
                    value={thC2ResCondition}
                    onChange={(e) => setthC2ResCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mb-1">Unresolved Condition <span className="text-red-500 italic mb-4">Example bar + gesamt - trinked_ec </span></label>
                <textarea
                    value={thC2UnResCondition}
                    onChange={(e) => setthC2UnResCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <h2 className="font-bold mt-4 mb-2">Fall : Abteilungen C6</h2>

                <label className="block text-gray-700 text-sm font-bold mb-1">Condition <span className="text-red-500 italic mb-4">Example bar + gesamt &gt; 1000 </span></label>


                <textarea
                    value={thC6Contiditon}
                    onChange={(e) => setThC6Contiditon(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mb-1">Result Condition <span className="text-red-500 italic mb-4">Example bar + gesamt - trinked_ec - (0,5/100) * (trink_geld + auf_rechnung) </span></label>

                <textarea
                    value={thC6ResCondition}
                    onChange={(e) => setThC6ResCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mb-1">Unresolved Condition <span className="text-red-500 italic mb-4">Example bar + gesamt - trinked_ec </span></label>

                <textarea
                    value={thC6UnResCondition}
                    onChange={(e) => setThC6UnResCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <h2 className="font-bold mt-4 mb-2">Bei verbleibenden Abteilungen</h2>
                <label className="block text-gray-700 text-sm font-bold mb-1">Condition <span className="text-red-500 italic mb-4">Example bar + gesamt &gt; 1000 </span></label>


                <textarea
                    value={thElseCondition}
                    onChange={(e) => setThElseCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mb-1">Result Condition <span className="text-red-500 italic mb-4">Example bar + gesamt - trinked_ec - (0,5/100) * (trink_geld + auf_rechnung) </span></label>

                <textarea
                    value={thElseResCondition}
                    onChange={(e) => setThElseResCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <label className="block text-gray-700 text-sm font-bold mb-1">Unresolved Condition <span className="text-red-500 italic mb-4">Example bar + gesamt - trinked_ec </span></label>

                <textarea
                    value={thElseUnResCondition}
                    onChange={(e) => setThElseUnResCondition(e.target.value)}
                    className="form-textarea mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                ></textarea>

                <button
                    onClick={handleSubmit}
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
                >
                    Update
                </button>

                {error && <p className="text-red-500 mt-2">{error}</p>} {/* Hiển thị thông báo lỗi */}
            </div>
        </div>
    );
}

export default EditrecipeService;
