import 'assets/css/App.css';
import Router from 'router';
import { Navigation } from 'components';
// import { AuthContextProvider } from 'context/AuthContext';

function App() {
  return (
      <Router />
  );
}

export default App;
