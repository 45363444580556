import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "components/api/httpService";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/auth/manage-password/reset-admin`, { email });

            if (response.status === 200) {
                setMessage("A reset link has been sent to your email.");
                setError("");
            } else {
                setError("Failed to send reset link. Please try again.");
                setMessage("");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            setMessage("");
        }
        setIsLoading(false);
    };

    return (
        <div className="max-w-md mx-auto p-5 border border-gray-300 rounded-lg shadow-lg mt-4">
            <h2 className="text-center text-2xl mb-5">Forgot Password</h2>
            <form onSubmit={handleForgotPassword}>
                <div className="mb-4">
                    <label className="block mb-1 font-semibold">Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md"
                    />
                </div>
                <button 
                    type="submit" 
                    disabled={isLoading}
                    className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {isLoading ? "Sending..." : "Send Reset Link"}
                </button>
            </form>
            <a 
                className="block w-full mt-4 py-2 text-center text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                href="./"
            >
                Login
            </a>

            {message && <p className="text-green-500 text-center mt-2">{message}</p>}
            {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        </div>
    );
};

export default ForgotPassword;
